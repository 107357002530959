/**
 * Functions everything video.
 *
 * @package neefschuetz
 */

export function videoControls() {
    // Elements
    const allVideoWrapper = document.querySelectorAll('.video-wrap');

    for (let i = 0; i < allVideoWrapper.length; i++) {
        let video = allVideoWrapper[i].querySelector('video');
        let playButton = allVideoWrapper[i].querySelector('.video-play');

        [video, playButton].forEach(item => {
            item.addEventListener("click", function () {
                if (video.paused == true) {
                    video.play();
                    video.setAttribute('controls', 'controls');
                    playButton.classList.add('d-none');
                } else {
                    video.pause();
                    video.removeAttribute('controls', 'controls');
                    playButton.classList.remove('d-none');
                }
            });
        })
    }
}
