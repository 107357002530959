/**
 * This file inits the scroll header
 *
 * @package neefschuetz
 * @since 1.0.0
 * @author hype.media <web@hype-media.de>
 */

export function scrollHeader() {
    const body = document.body;
    const header = document.querySelector('.header-main-menu');

    // Body Classes
    const goUp = 'up';
    const goDown = 'down';

    // Variables
    let lastScroll = 0;
    let start = 250;

    // Check if header with fixed-top class exists and has fixed-top class
    if (header && header.classList.contains('fixed-top')) {
        window.addEventListener('scroll', () => {
            const currentScroll = window.pageYOffset;

            if (currentScroll > start) {
                if (currentScroll === 0) {
                    body.classList.remove(goUp);
                    return;
                }

                if (currentScroll > lastScroll && !body.classList.contains(goDown)) {
                    body.classList.remove(goUp);
                    body.classList.add(goDown);
                } else if (currentScroll < lastScroll && body.classList.contains(goDown)) {
                    body.classList.remove(goDown);
                    body.classList.add(goUp);
                }
                lastScroll = currentScroll;
            } else {
                body.classList.remove(goUp);
            }
        });
    }
}