/**
 * ==== Bootstrap Plugins ====
 * Bootstrap plugins are auto-invoked
 */
import Collapse from 'bootstrap/js/dist/collapse';
import masonry from 'masonry-layout'
import featherlight from 'featherlight';

/**
 * ==== Helpers on page load ====
 * - pageInit: Run some general stuff
 * - resetPage: Move footer to the bottom of the page
 */
import { resetPage, pageInit } from "./theme/reset";
import { scrollHeader } from "./theme/scroll";
import { sliderConfiguration } from "./theme/sliders";
import { videoControls } from "./theme/video";

// Aufruf der Funktionen
pageInit();
resetPage();
scrollHeader();
sliderConfiguration();
videoControls();

// Eventlistener für Window Resize
window.addEventListener("resize", function () {
    resetPage();
});
